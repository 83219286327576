@use 'common'

.wrapper
	display: contents

	&.is_locked
		display: block
		position: absolute
		inset: 0
		overflow: hidden
