@use 'common'
@import 'hiding-header/dist/style.css'
@import 'collapsible-react-component/dist/index.css'

@font-face
	font-family: 'objectSans'
	src: url('/fonts/objectSans-Regular.woff2') format('woff2'), url('/fonts/objectSans-Regular.woff') format('woff')
	font-weight: 400
	font-style: normal
	font-display: swap

@font-face
	font-family: common.$objectSans
	src: url('/fonts/objectSans-Bold.woff2') format('woff2'), url('/fonts/objectSans-Bold.woff') format('woff')
	font-weight: 700
	font-style: normal
	font-display: swap

// @font-face
// 	font-family: common.$avenirNext
// 	src: url('/fonts/avenirNext-Regular.woff2') format('woff2'), url('/fonts/avenirNext-Regular.woff') format('woff')
// 	font-weight: 400
// 	font-style: normal
// 	font-display: swap

// @font-face
// 	font-family: common.$avenirNext
// 	src: url('/fonts/avenirNext-Medium.woff2') format('woff2'), url('/fonts/avenirNext-Medium.woff') format('woff')
// 	font-weight: 500
// 	font-style: normal
// 	font-display: swap

// @font-face
// 	font-family: common.$avenirNext
// 	src: url('/fonts/avenirNext-DemiBold.woff2') format('woff2'), url('/fonts/avenirNext-DemiBold.woff') format('woff')
// 	font-weight: 600
// 	font-style: normal
// 	font-display: swap

@font-face
	font-family: common.$pangram
	src: url('/fonts/pangram-Regular.otf') format('opentype')
	font-weight: 400
	font-style: normal
	font-display: swap

@font-face
	font-family: common.$ppWoodland
	src: url('/fonts/ppWoodland-Regular.otf') format('opentype')
	font-weight: 400
	font-style: normal
	font-display: swap

@font-face
	font-family: 'caprasimo'
	src: url('/fonts/caprasimo.woff2') format('woff2'), url('/fonts/caprasimo.woff') format('woff')
	font-weight: 400
	font-style: normal
	font-display: swap

@font-face
	font-family: common.$sourceSans3
	src: url('/fonts/sourceSans3-Light.woff2') format('woff2'), url('/fonts/sourceSans3-Light.woff') format('woff')
	font-weight: 300
	font-style: normal
	font-display: swap

@font-face
	font-family: common.$sourceSans3
	src: url('/fonts/sourceSans3-Regular.woff2') format('woff2'), url('/fonts/sourceSans3-Regular.woff') format('woff')
	font-weight: 400
	font-style: normal
	font-display: swap

@font-face
	font-family: common.$sourceSans3
	src: url('/fonts/sourceSans3-Medium.woff2') format('woff2'), url('/fonts/sourceSans3-Medium.woff') format('woff')
	font-weight: 500
	font-style: normal
	font-display: swap

@font-face
	font-family: common.$sourceSans3
	src: url('/fonts/sourceSans3-SemiBold.woff2') format('woff2'), url('/fonts/sourceSans3-SemiBold.woff') format('woff')
	font-weight: 600
	font-style: normal
	font-display: swap

@font-face
	font-family: common.$sourceSans3
	src: url('/fonts/sourceSans3-Bold.woff2') format('woff2'), url('/fonts/sourceSans3-Bold.woff') format('woff')
	font-weight: 600
	font-style: normal
	font-display: swap

@font-face
	font-family: common.$fustat
	src: url('/fonts/fustat-Regular.woff2') format('woff2'), url('/fonts/fustat-Regular.woff') format('woff')
	font-weight: 600
	font-style: normal
	font-display: swap

html,
body
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

	line-height: common.$line-height
	padding: 0
	margin: 0
	font-family: common.$sourceSans3Stack

	h1, h2, h3, h4, h5
		font-family: common.$objectSansStack
		letter-spacing: common.$letter-spacing
		margin-bottom: .4em

:root
	overflow-y: scroll

body
	min-height: 100vh
	min-height: 100svh
	display: grid
	grid-template-rows: 1fr

#__next
	display: contents

a,
button
	-webkit-tap-highlight-color: transparent

a
	color: inherit
	text-decoration: none

button
	cursor: pointer

*, *::before, *::after
	box-sizing: border-box

:root
	--global-horizontal-spacing: 1.5rem

	--content-width-small: #{common.$contentWidth-small}
	--content-width-normal: #{common.$contentWidth-normal}
	--content-width-wide: #{common.$contentWidth-wide}

	--CardTileContent-text-topSpacing: 1.25rem
	--CardTileContent-mainContent-paddingBlock: 1.875rem

	@media (min-width: common.$break30)
		--CardTileContent-mainContent-paddingBlock: 3.125rem

	@media (min-width: common.$break62)
		--global-horizontal-spacing: 2rem

.hidingHeader
	z-index: 9999

.click-for-sound-btn
	display: none !important

.hs-form-field > label
	display: none

.form-columns-2
	flex-direction: column !important
	flex: 1
	margin: unset
	padding: unset

.form-columns-2 > .hs_lastname
	margin-top: -0.65rem

.form-columns-1
	width: 100%

.no-list
	margin-top: 0.5rem

.input
	height: 56px

.input > input
	height: 100%
